import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
@Injectable()
export class FilesForm {
  constructor(private fb: FormBuilder) {}

  uploadForm(): FormGroup {
    return this.fb.group({
      file: [null]
    });
  }

  getByPath(): FormGroup {
    return this.fb.group({
      path: [null],
    });
  }

}
