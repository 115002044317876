import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AsyncPipe} from '@angular/common';
import {GuestComponent} from '@revolve-app/app/theme/layouts/guest/guest.component';
import {HttpClientModule} from "@angular/common/http";
import {NotifierComponent} from "@revolve-app/app/shared/notifier/notifier.component";
import {FilesForm} from "@revolve-app/app/core/features/files/forms/files.form";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "@revolve-app/app/shared/forms/buttons/button/button.component";
import {
  platformSettingsGetByIdRequest
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.actions";
import {Store} from "@ngrx/store";
import {env} from "@revolve-app/env/env";
import {
  PlatformSettingsModule
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.module";
import {
  PlatformSettingsService
} from "@revolve-app/app/core/features/platform-settings/services/platform-settings.service";
import {isBrowser} from "@revolve-app/app/core/utils/is.browser";
import {SocketModule} from "@revolve-app/app/core/features/socket/store/socket/socket.module";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    GuestComponent,
    HttpClientModule,
    NotifierComponent,
    ReactiveFormsModule,
    ButtonComponent,
    PlatformSettingsModule,
    SocketModule,
    TranslateModule
  ],
  providers:[
    FilesForm,
    PlatformSettingsService,
    // {
    //   provide: TranslateCompiler,
    //   useClass: TranslateCompiler // Assuming you have a concrete implementation
    // },
    // {
    //   provide: TranslateParser,
    //   useClass: TranslateParser // Assuming you have a concrete implementation
    // },
    // {
    //   provide: MissingTranslationHandler,
    //   useClass: MissingTranslationHandler // Assuming you have a concrete implementation
    // }
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  public files:FormGroup;

  selectedLanguage = 'en';
  public platformId: string = env.platformId
  constructor(private filesForm: FilesForm,
              private store: Store,
              private translateService: TranslateService) {
    this.files = this.filesForm.uploadForm();
    if (isBrowser()) {
      this.store.dispatch(platformSettingsGetByIdRequest({id: this.platformId}));
    }
  }

  ngOnInit(): void {
    if (isBrowser()) {
      if(StorageService.getItem(StorageKeys.LANGUAGE)){
        let lang: any = StorageService.getItem(StorageKeys.LANGUAGE);
        this.translateService.use(lang);
      } else {
        this.translateService.use('hy');
      }
    }
  }
}
