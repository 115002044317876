import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {
  PlatformSettingsService
} from "@revolve-app/app/core/features/platform-settings/services/platform-settings.service";
import * as PlatformSettingActions
  from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.actions";
import {map, mergeMap} from "rxjs/operators";
import {socketInitializeConnection} from "@revolve-app/app/core/features/socket/store/socket/socket.actions";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";

@Injectable()
export class PlatformSettingsEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private platformSettingsService: PlatformSettingsService,
  ) {}


  getByIdPlatform$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlatformSettingActions.platformSettingsGetByIdRequest),
      mergeMap((action) => {
          return this.platformSettingsService.getById(action.id)
            .pipe(
              map((payload)=> {
                this.store.dispatch(socketInitializeConnection(payload.platform.name, payload.ipSession.ipSessionKey));
                StorageService.setItem(StorageKeys.PLATFORM_NAME, payload.platform.name)
                StorageService.setItem(StorageKeys.IP_SESSION_KEY, payload.ipSession.ipSessionKey)
                return PlatformSettingActions.platformSettingsGetByIdSuccess({ payload: payload })
              })
            )
        }
      )
    )
  });

  getAllPlatform$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PlatformSettingActions.getAllPlatformsRequestStart),
      mergeMap((action) => {
          return this.platformSettingsService.getAll({limit: 100})
            .pipe(
              map((payload)=> {
                return PlatformSettingActions.getAllPlatformsRequestSuccess({ payload })
              })
            )
        }
      )
    )
  });


}
