import {Injectable} from "@angular/core";
import {HttpService} from "@revolve-app/app/core/services/network/http/http.service";
import {env} from "@revolve-app/env/env";
import {MicroService} from "@revolve/meta-data/metadata/enums/MicroService";
import {PlatformResponseInterface} from "@revolve/meta-data/roots/platform-microservice/response";
import IpSession from "@revolve/meta-data/metadata/interfaces/IpSession";
import {Observable} from "rxjs";

@Injectable()
export class PlatformSettingsService {
  constructor(
    private httpService: HttpService
  ) {}
  private baseUrl = `api/gateway/${MicroService.PLATFORM}/${env.backendVersion}`;
  getById(id:string) : Observable< {platform: PlatformResponseInterface, ipSession: IpSession}>{
    return this.httpService.get(`${this.baseUrl}/platform/${id}`, {}, {platformId: id} )
  }

  getAll(params: any){
    return this.httpService.get(`${this.baseUrl}/platform/list`, params)
  }
}
