import {NgModule} from "@angular/core";
import {provideState} from "@ngrx/store";
import {
  PLATFORM_SETTINGS_FEATURE_NAME,
  platformReducer
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";
import {provideEffects} from "@ngrx/effects";
import {PlatformSettingsEffect} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.effect";
import {PlatformSettingsService} from "@revolve-app/app/core/features/platform-settings/services/platform-settings.service";

@NgModule({
  imports: [],
  providers: [
    provideState(PLATFORM_SETTINGS_FEATURE_NAME, platformReducer),
    provideEffects(PlatformSettingsEffect),
    PlatformSettingsService
  ]
})

export class PlatformSettingsModule {}
