
export enum SocketEventType {
    NEW_MESSAGE = 'newMessage',
    UPDATE_ROOM = 'updateRoom',
    ADD_ROOM = 'addRoom',
    CONNECT_PLATFORMS = 'connectPlatforms',
    DISCONNECT_PLATFORM = 'disconnectPlatform',
    ADD_MESSAGES_COUNT = 'addMessageCount',
    PLATFORM_UPDATE = 'platformUpdate',
    UPDATE_USER = 'updateUser',
    SEEN_MESSAGE = 'seenMessage',
    INITIAL_INFO = "initialInfo",
}
