import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketService } from '@revolve-app/app/core/features/socket/services/socket.service';
import * as socketActions from './socket.actions';
import {catchError, EMPTY, map, mergeMap, of} from 'rxjs';
import {tap} from "rxjs/operators";

@Injectable()
export class SocketEffect {
  constructor(
    private actions$: Actions,
    private store: Store,
    private socketService: SocketService
  ) {}

  joinRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketActions.socketJoinRoom),
      mergeMap(action =>
        this.socketService.joinRoom(action.roomName, action.roomId).pipe(
          map(() => socketActions.socketJoinRoomSuccess({
            roomName: action.roomName,
            roomId: action.roomId
          })),
          catchError(() => of({ type: '[SOCKET] join room failure' }))
        )
      )
    )
  );

  leaveRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketActions.socketLeaveRoom),
      mergeMap(action =>
        this.socketService.leaveRoom(action.roomName, action.roomId).pipe(
          map(() => socketActions.socketLeaveRoomSuccess({
            roomName: action.roomName,
            roomId: action.roomId
          })),
          catchError(() => of({ type: '[SOCKET] leave room failure' }))
        )
      )
    )
  );

  reconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketActions.socketReconnect),
      mergeMap(action =>
        this.socketService.reconnect().pipe(
          map(() => socketActions.socketReconnectSuccess()),
          catchError(() => of({ type: '[SOCKET] reconnect failure' }))
        )
      )
    )
  );

  initializeConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(socketActions.socketInitializeConnection),
      tap(({ platformName, ipSessionKey }) =>
        this.socketService.initializeConnection(platformName, ipSessionKey)
      ),
      map(() => ({ type: 'SOCKET_INITIALIZATION_SUCCESS' })),
      catchError(() => EMPTY)
    )
  );
}
