// src/app/services.service.ts
import {Injectable} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {env} from "@revolve-app/env/env";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";
import {Observable, of} from "rxjs";
import {SocketActionType} from "@revolve/meta-data/metadata/enums/SocketActionType";
import {Store} from "@ngrx/store";
import {messageSeenMessage, onNewMessage} from "@revolve-app/app/core/features/chat/store/message/message.actions";
import {
  onAddRoom,
  onUpdateRoom,
  roomSeenMessage,
  roomsNewMessage
} from "@revolve-app/app/core/features/chat/store/rooms/rooms.actions";
import {SocketEventType} from "@revolve/meta-data/metadata/enums/SocketEventType";
import {
  changeGlobalMessagesCount,
  onSocketInitialInfo
} from "@revolve-app/app/core/features/accounts/store/me/me.actions";

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public socket!: Socket;

  constructor(private store: Store) {}

  // Initialize connection
  initializeConnection(platformName: string, ipSessionKey: string): void {
    this.socket = io(env.serviceRegisterUrl + '/' + platformName, {
      transports: ['websocket'],
      query: {
        token: StorageService.getItem(StorageKeys.TOKEN)?StorageService.getItem(StorageKeys.TOKEN): '',
        platformId: env.platformId,
        ipSessionKey: ipSessionKey
      }
    });

    this.socket.on('connect', () => {
      console.log('Successfully connected to the server.');
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from the server.');
    });

    this.socket.on('connect_error', (error) => {
      console.log('Connection failed: ', error);
    });

    this.socket.on('error', (error) => {
      console.log('Connection error: ', error);
    });
    this.addListeners();
  }

  // Emit events
  emit(event: string, data?: any) {
    this.socket.emit(event, data);
  }

  // Join a room
  joinRoom(roomName: string, roomId: string) {
    this.emit('join-room', { roomName, roomId });
    return of();
  }

  // Leave a room
  leaveRoom(roomName: string, roomId: string) {
    this.emit('leave-room', { roomName, roomId });
    return of();
  }
  reconnect(): Observable<void> {
    this.socket.disconnect();
    this.initializeConnection(StorageService.getItem(StorageKeys.PLATFORM_NAME) as string, StorageService.getItem(StorageKeys.IP_SESSION_KEY) as string);
    return of();
  }
  private addListeners() {
    this.socket.on(SocketActionType.ADD, (data) => this.handleAdd(data));
    this.socket.on(SocketActionType.UPDATE, (data) => this.handleUpdate(data));
    this.socket.on(SocketActionType.DELETE, (data) => this.handleDelete(data));
    this.socket.on(SocketActionType.INITIAL_INFO, (data) => this.handleInitialInfo(data))
  }

  // Handlers for action types
  private handleAdd(data: { type: SocketEventType, data: any}) {
    console.log('Handle ADD action:', data);
    if(data.type === SocketEventType.NEW_MESSAGE){
      this.store.dispatch(onNewMessage({newMessage: data.data}));
    }
    if(data.type === SocketEventType.ADD_ROOM){
      this.store.dispatch(onAddRoom({payload: data.data}));
    }


  }

  private handleUpdate(data: { type: SocketEventType, data: any}) {
    console.log('Handle UPDATE action:', data);
    if(data.type === SocketEventType.UPDATE_ROOM){
      this.store.dispatch(onUpdateRoom({room: data.data}));
    }
    if(data.type === SocketEventType.ADD_MESSAGES_COUNT){
      this.store.dispatch(roomsNewMessage({roomId:data.data.roomId, count: data.data.count, newMessage: data.data.newMessage, chatMemberId: data.data.chatMemberId}))
      this.store.dispatch(changeGlobalMessagesCount({count: data.data.count, chatMemberId: data.data.chatMemberId, roomId: data.data.roomId}))
    }
    if(data.type === SocketEventType.SEEN_MESSAGE){
      this.store.dispatch(roomSeenMessage({roomId:data.data.roomId, seenMemberId: data.data.seenMemberId, messageId: data.data.messageId}))
      this.store.dispatch(messageSeenMessage({roomId:data.data.roomId, seenMemberId: data.data.seenMemberId, messageId: data.data.messageId}))
    }
    // Add your handling logic here
  }

  private handleDelete(data: { type: SocketEventType, data: any}) {
    console.log('Handle DELETE action:', data);
    // Add your handling logic here
  }

  private handleInitialInfo(data: { type: SocketEventType, data: any}){
    console.log('handleInitialInfo', data);
    this.store.dispatch(onSocketInitialInfo({payload: data.data}));
  }
}
