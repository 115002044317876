import {NgModule} from "@angular/core";
import {provideEffects} from "@ngrx/effects";
import {SocketEffect} from "@revolve-app/app/core/features/socket/store/socket/socket.effect";
import {SocketService} from "@revolve-app/app/core/features/socket/services/socket.service";

@NgModule({
  imports: [],
  providers: [
    provideEffects(SocketEffect),
    SocketService
  ]
})

export class SocketModule { }
